import gsap from "gsap";

// Анимация иконок в секции "Преимущества"
gsap.to(".time-big-arrow", { rotation: '+=360', repeat: -1, transformOrigin: '0% 100%', duration: 3 });
gsap.to(".time-small-arrow", { rotation: '+=360', repeat: -1, duration: 2 });
gsap.to(".music-1", { y: '-5', repeat: -1, duration: 0.5, opacity: 0 });
gsap.to(".music-2", { y: '-5', repeat: -1, duration: 0.9, opacity: 0 });
gsap.to(".music-3", { y: '-5', repeat: -1, duration: 1.3, opacity: 0 });
gsap.to(".message-1", { y: '-15', repeat: -1, duration: 2, opacity: 0, repeatDelay: 0 });
gsap.to(".message-2", { y: '-15', repeat: -1, duration: 2, opacity: 0, repeatDelay: 2 });
gsap.to(".message-3", { y: '-15', repeat: -1, duration: 2, opacity: 0, repeatDelay: 4 });

//
// gsap.registerPlugin(DrawSVGPlugin);

const cardPrice = {

}
/**
data-card="5" []
.n-card-cashback__cashback
.n-card-cashback__oldprice
.n-card-price__price
.n-card-price__min
.n-card-total__total
.n-card-total__old
.n-card-amount
.n-card-button

	"id": 0,
	"discount": false,
 	"cashBack": "кешбэк 10%",
	"oldPrice": "### р.",
	"price": "580 р.",
	"oldFullPrice": "### р.",
	"fullPrice": "всего 8700 р.",
	"min": "80 мин",

	"trainingSessions": "15 тренировок",
	"link": "https://n725762.yclients.com/"
*/

const PRICES_DATA = {
	'rent-0': [
		{
			"id": 0,
			"discount": false,
			"oldPrice": "### р.",
			"price": "650 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 9750 р.",
			"min": "70 мин",
			"cashBack": "купили 66 раз",
			"trainingSessions": "15 тренировок",
			"link": "https://o1779.yclients.com/loyalty/subscription/554817"
		},
		{
			"id": 1,
			"discount": false,
			"oldPrice": "### р.",
			"price": "670 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 8040 р.",
			"min": "70 мин",
			"cashBack": "купили 89 раз",
			"trainingSessions": "12 тренировок",
			"link": "https://o1779.yclients.com/loyalty/subscription/554816"
		},
		{
			"id": 2,
			"discount": false,
			"oldPrice": "### р.",
			"price": "679 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 6799 р.",
			"min": "70 мин",
			"cashBack": "купили 189 раза",
			"trainingSessions": "10 тренировок",
			"link": "https://o1779.yclients.com/loyalty/subscription/554815"
		},
		{
			"id": 3,
			"discount": false,
			"oldPrice": "### р.",
			"price": "730 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 5840 р.",
			"min": "70 мин",
			"cashBack": "купили 48 раз",
			"trainingSessions": "8 тренировок",
			"link": "https://o1779.yclients.com/loyalty/subscription/554814"
		},
		{
			"id": 4,
			"discount": false,
			"oldPrice": "### р.",
			"price": "790 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 3950 р.",
			"min": "70 мин",
			"cashBack": "купили 41 раз",
			"trainingSessions": "5 тренировок",
			"link": "https://o1779.yclients.com/loyalty/subscription/554813"
		},
		{
			"id": 5,
			"discount": false,
			"oldPrice": "### р.",
			"price": "825 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 2475 р.",
			"min": "70 мин",
			"cashBack": "купили 23 раза",
			"trainingSessions": "3 тренировки",
			"link": "https://o1779.yclients.com/loyalty/subscription/554811"
		},
		{
			"id": 6,
			"discount": true,
			"oldPrice": "900 р.",
			"price": "836 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 836 р.",
			"min": "70 мин",
			"cashBack": "кешбэк 5%",
			"trainingSessions": "1 тренировка",
			"link": "https://n725762.yclients.com/"
		}
	],
	'rent-1': [
		{
			"id": 0,
			"discount": false,
			"oldPrice": "### р.",
			"price": "710 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 10650 р.",
			"min": "70 мин",
			"cashBack": "купили 66 раз",
			"trainingSessions": "15 тренировок",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 1,
			"discount": false,
			"oldPrice": "### р.",
			"price": "729 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 8748 р.",
			"min": "70 мин",
			"cashBack": "купили 89 раз",
			"trainingSessions": "12 тренировок",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 2,
			"discount": false,
			"oldPrice": "### р.",
			"price": "739 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 7399 р.",
			"min": "70 мин",
			"cashBack": "купили 189 раза",
			"trainingSessions": "10 тренировок",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 3,
			"discount": false,
			"oldPrice": "### р.",
			"price": "799 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 6399 р.",
			"min": "70 мин",
			"cashBack": "купили 48 раз",
			"trainingSessions": "8 тренировок",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 4,
			"discount": false,
			"oldPrice": "### р.",
			"price": "865 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 4325 р.",
			"min": "70 мин",
			"cashBack": "купили 41 раз",
			"trainingSessions": "5 тренировок",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 5,
			"discount": false,
			"oldPrice": "### р.",
			"price": "910 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 2730 р.",
			"min": "70 мин",
			"cashBack": "купили 23 раза",
			"trainingSessions": "3 тренировки",
			"link": "https://o1779.yclients.com/loyalty"
		},
		{
			"id": 6,
			"discount": true,
			"oldPrice": "990 р.",
			"price": "919 р.",
			"oldFullPrice": "### р.",
			"fullPrice": "всего 919 р.",
			"min": "70 мин",
			"cashBack": "кешбэк 5%",
			"trainingSessions": "1 тренировка",
			"link": "https://n725762.yclients.com/"
		}
	]
}

// Константы для текстов
const RENT_TEXTS = {
    'rent-0': 'ℹ️ Это цены, если ты хочешь заняться в одиночку, или один на один с тренером',
    'rent-1': 'ℹ️ Это цены, если вы занимаетесь в группе от трёх человек, включая тренера'
};

// Ключ для localStorage
const RENT_STORAGE_KEY = 'activeRentTab';

// Функция инициализации
const initRentToggle = () => {
    const rentButtons = document.querySelectorAll('.rent-toggle__button');
    const rentText = document.querySelector('.rent-text');
    
    // Получаем сохранённое состояние или используем значение по умолчанию
    const savedState = localStorage.getItem(RENT_STORAGE_KEY) || 'rent-0';
    
    // Обновляем UI и рендерим карточки при загрузке
    updateRentUI(savedState, rentButtons, rentText);
    
    // Добавляем обработчики событий для кнопок
    rentButtons.forEach(button => {
        button.addEventListener('click', () => {
            const rentType = button.getAttribute('data');
            localStorage.setItem(RENT_STORAGE_KEY, rentType);
            updateRentUI(rentType, rentButtons, rentText);
        });
    });
};

// Функция обновления UI
const updateRentUI = (activeType, buttons, textElement) => {
    // Правильно обновляем классы кнопок
    buttons.forEach(button => {
        const isActive = button.getAttribute('data') === activeType;
        button.classList.toggle('active', isActive);
    });
    
    // Обновляем текст
    textElement.textContent = RENT_TEXTS[activeType];
    
    // Рендерим карточки с правильным типом
    renderCards(PRICES_DATA, activeType);
};

// Запускаем после загрузки DOM
document.addEventListener('DOMContentLoaded', initRentToggle);


const renderCards = (listCards, rentType = 'rent-0') => {
    const cardsElements = document.querySelectorAll('[data-card]');
    const currentPrices = listCards[rentType];

	for (const card of cardsElements) {
        const cardId = card.getAttribute('data-card') * 1;
        const cardData = currentPrices.find(obj => obj.id === cardId);
        
        if (!cardData) continue; // пропускаем если нет данных для этой карточки
		const cardElements = {
			oldPrice: card.querySelector(`.n-card-cashback__oldprice`),
			price: card.querySelector(`.n-card-price__price`),
			oldFullPrice: card.querySelector(`.n-card-total__old`),
			fullPrice: card.querySelector(`.n-card-total__total`),
			min: card.querySelector(`.n-card-price__min`),
			cashBack: card.querySelector(`.n-card-cashback__cashback`),
			trainingSessions: card.querySelector(`.n-card-amount`),
			link: card.querySelector(`a.n-card-button`),
			discount: card,
		};
		for (const cardCell in cardElements) {
			const cardValue = cardData[cardCell];
			const cardElement = cardElements[cardCell]
			if (cardCell === "link") {cardElement.setAttribute('href', cardValue)}
			else if (cardCell === "discount") {cardElement.setAttribute('data-discount', cardValue)}
			else {cardElement.textContent = cardValue}
		}
	}
	document.querySelector(`[data-loaded="false"]`).setAttribute('data-loaded', 'true')
}

// Табы видов тренировок

const trainingImg = document.getElementsByClassName('training-img')
const trainingTitle = document.getElementsByClassName('training-list__item')

for (let i = 0; i < trainingTitle.length; i++) {
	trainingTitle[i].addEventListener('click', () => {
		for (let i = 0; i < trainingTitle.length; i++) {
			trainingTitle[i].classList.remove('active')
			trainingImg[i].style.display = 'none'
		}
		trainingTitle[i].classList.add('active')
		trainingImg[i].style.display = 'block'
	}, { passive: true })
}

// Переключение цен
const priceToggle = document.querySelectorAll('.price-toggle__button')
const prices = document.querySelectorAll('.prices')
function activepriceToggle() {
	priceToggle.forEach((item) => {
		item.classList.remove('active');
		prices.forEach((item) => {
			item.classList.remove('active');
		})
	});
	let temp = +this.getAttribute('data')
	this.classList.add('active');
	prices[temp].classList.add('active')
	document.getElementsByClassName('price')[1].classList.add('price-hover')
}
priceToggle.forEach((item) => item.addEventListener('click', activepriceToggle, { passive: true }))


//анимация мобильного меню
const list = document.querySelectorAll('.mobile-menu__list')
function activeLink() {
	list.forEach((item) =>
		item.classList.remove('active'));
	this.classList.add('active');
}
list.forEach((item) =>
	item.addEventListener('click', activeLink, { passive: true }))

// coach card animation
const coach = document.getElementsByClassName('coach')
const coachArrow = document.getElementsByClassName('coach-arrow')

for (let i = 0; i < coach.length; i++) {
	coachArrow[i].addEventListener('click', () => {
		coach[i].classList.toggle('active')
	}, { passive: true })
}
